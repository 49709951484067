.naves-card {
    width: 300px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: white;
    -webkit-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
    -moz-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
    box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);

    &:hover {
        -webkit-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.2);
        -moz-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.2);
        box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.2);
        cursor: pointer;
    }

    &__image {
        //width: 100%;
        height: 240px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &__title {
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 1%;
        padding: 20px 0px;
        height: 50px;
    }
}