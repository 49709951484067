@import './variables';
@import './libs/normalize';
@import './components/all';

body {
  background-color: $background;
}

.wrapper {
  width: 100%;
  height: 100%;
  background-color: $background;
  //min-width: 390px;
}

.content {
  padding: 40px 0;

  &__title {
    padding: 20px 0px;
  }

  &__podtitle {
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    text-align: center;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 80px 0px;

    @media (max-width: 1199px) {
      justify-content: space-between;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;

    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1150px) {
      .categories {
        width: 100%;
        overflow: scroll;

        ul {
          width: 1094px;
        }
      }
    }
  }

  &__card {
    display: flex;
    width: 340px;
    justify-content: center;
    background: white;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
    -moz-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
    box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);

    p {
      padding: 0px 5px;
    }
  }

  &__card:hover {
    -webkit-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.2);
    -moz-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.2);
    box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.2);
  }

  &__tehcard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    margin: 30px 0px;
    grid-row-gap: 20px;

    @media (max-width: 1150px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 750px) {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 10px;
    }
  }

  &__navescard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    margin: 30px 0px;
    grid-row-gap: 30px;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 10px;
    }
  }


  &__infopage {
    margin: 50px 0px;
  }

  &__searchpage {
    margin: 20px 0 20px 0;
  }
}

.grid-infopage {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 995px) {
    grid-template-columns: 1fr;

    .carousel-inner img {
      height: auto !important;
      max-width: 100%;
    }

    .spec-tizer {
      margin-top: 20px;

      h4 {
        padding-left: 0;
      }

      ul {
        padding-left: 0 !important;
      }

      .pdf__link {
        padding-left: 0;
      }
    }

    .description {
      ul {
        padding-left: 0;
      }
    }
  }
}

// Настройки экшен тизера запчастей
.spec-tizer {
  h4 {
    padding-left: 2rem;
    margin: 10px 0px;
  }
}

//настройка карусели каталога техники

.img-section {
  margin: 0 auto;
}

.carousel-inner img {
  margin: 0 auto;
  width: auto;
  max-height: 400px !important;
  //width: 800px;
}

.tizer-title {
  padding-left: 2rem;
  font-weight: 800;
  margin-bottom: 15px;
}

.tizer-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .text_connection {
    display: flex;
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
    align-items: center;
  }
}

.spec-tizer_li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.pdf__link {
  padding-left: 2rem;
  cursor: pointer;

  a:hover {
    color: $orange !important;
  }
}

.description {
  margin-top: 50px;
}

.container__my {
  width: $container-width;
  margin: 0 auto;

  &--cart {
    max-width: 820px;
    margin: 90px auto;

    .content__title {
      margin: 0;
    }
  }
}

.main-background {
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, #181510 7%, rgba(0, 0, 0, 0) 93%), url(../assets/img/homeImage.jpeg);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  //background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 600px;

}

.home__teaser {
  display: flex;
  flex-direction: column;
  width: 50%;

  .teaser__title {
    color: #ffff;
    padding: 0px 0px;
  }

  p {
    color: #ffff;
  }
}

.accent-bar {
  background-color: $orange;
  width: 50px;
  height: 4px;
  margin-bottom: 10px;
}

.cart {
  &__top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .content__title {
    display: flex;
    align-items: center;
    font-size: 32px;

    svg {
      position: relative;
      top: -2px;
      width: 30px;
      height: 30px;
      margin-right: 10px;

      path {
        stroke: $black;
        stroke-width: 1.9;
      }
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include noselect();

    span {
      display: inline-block;
      margin-left: 7px;
      color: #b6b6b6;
      font-size: 18px;
    }

    span,
    svg,
    path {
      transition: all $duration ease-in-out;
    }

    &:hover {
      svg {
        path {
          stroke: darken($color: #b6b6b6, $amount: 50);
        }
      }

      span {
        color: darken($color: #b6b6b6, $amount: 50);
      }
    }
  }

  &__item {
    display: flex;
    width: 100%;
    //border-top: 1px solid $gray-line;
    padding-top: 15px;
    margin-top: 30px;
    justify-content: space-around;

    &-img {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 10%;

      img {
        width: 80px;
        height: 80px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 40%;

      h3 {
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: 0.01em;
      }

      p {
        font-size: 18px;
        color: #8d8d8d;
      }
    }

    &-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 13%;

      &-minus {
        svg {
          path:first-of-type {
            display: none;
          }
        }
      }

      b {
        font-size: 22px;
      }
    }

    &-remove {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 4%;

      .button {
        border-color: darken($color: $gray-line, $amount: 10);
      }

      svg {
        transform: rotate(45deg);

        path {
          fill: darken($color: $gray-line, $amount: 15);
        }
      }

      .button {
        svg {
          width: 11.5px;
          height: 11.5px;
          position: relative;
        }

        &:hover,
        &:active {
          border-color: darken($color: $gray-line, $amount: 80);
          background-color: darken($color: $gray-line, $amount: 80);
        }
      }
    }
  }

  &__bottom {
    margin: 50px 0;

    &-details {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 22px;

        &:last-of-type {
          b {
            color: $orange;
          }
        }
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .go-back-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 210px;

        border-color: darken($color: $gray-line, $amount: 10);

        span {
          color: darken($color: $gray-line, $amount: 20);
          font-weight: 500;
          font-weight: 600;
        }

        &:hover {
          background-color: darken($color: $gray-line, $amount: 90);
          border-color: darken($color: $gray-line, $amount: 90);

          span {
            color: $gray-line;
          }
        }

        svg {
          margin-right: 12px;

          path {
            fill: transparent;
            stroke-width: 2;
          }
        }
      }

      .pay-btn {
        font-size: 16px;
        font-weight: 600;
        width: 210px;
        padding: 16px;
      }
    }
  }

  &--empty {
    margin: 0 auto;
    margin-top: 100px;
    width: 560px;
    text-align: center;

    @media (max-width: 570px) {
      width: 300px;
    }

    h2 {
      font-size: 32px;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      line-height: 145.4%;
      letter-spacing: 0.01em;
      color: #777777;
    }

    icon {
      position: relative;
      top: 2px;
    }

    img {
      display: block;
      width: 300px;
      margin: 45px auto 60px;
    }

    .button--black {
      padding: 12px 0 14px;
      width: 230px;
      margin: 50px 0px;
      font-weight: 600;
      font-size: 18px;
    }
  }
}

//Настройки страницы Запчасти
.backgraund__parts {
  background: #fff;
}

.partnavbar {
  height: 60px;
  background-color: #1A1A1A;

  ul {
    margin: 0 0;
    padding: 0 0;
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-items: center;
    height: 100%;
    align-items: center;
  }

  li {
    border-right: 1px solid #B0AB99;
    width: 100%;
    text-align: center;
  }

  li:last-child {
    border-right: none;
  }

  a {
    padding-bottom: 16px;
    color: #B0AB99;

    &:hover {
      color: #DFD045;
    }
  }
}

.container__partspage {
  width: 80%;
  margin: 50px auto;

  @media (max-width: 831px) {
    width: 95%;
  }
}

.advantage__card {
  display: grid;
  grid-template-columns: 150px 1fr;
  padding: 50px 0;
  margin: 20px 0 20px 0;
  border-bottom: 1px solid #B0AB99;

  @media (max-width: 700px) {
    grid-template-columns: none;
    justify-items: center;
  }
}

.advantage__card:last-child {
  border-bottom: none;
}

.advantage__img {
  //background: #FCF9F0;
  background-image: linear-gradient(to right top, #659b91, #6f957a, #7f8d66, #8f835a, #9d7759);
  opacity: 0.5;
  height: 150px;
  width: 150px;
  display: grid;
  place-items: center;
  border-radius: 96px;

  @media (max-width: 700px) {
    margin-bottom: 20px;
  }
}

.advantage__info {
  display: grid;
  align-items: end;
  margin-left: 80px;

  @media (max-width: 700px) {
    margin-left: 0;
  }
}

.more__span {
  font-size: 12px;
  width: fit-content;
  cursor: pointer;

  img {
    width: 16px;
  }

  span {
    padding-right: 10px;
  }
}

.active__more {
  transform: rotate(180deg);
}

//Настройки страницы indexParts
.text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 50px;

  &--tab {
    margin-bottom: 20px;
  }
}

//Настройка страницы filterPage
.filter_page {
  padding-bottom: 1px;

}

.filterImage {

  width: 100%;
  /*width: -moz-available;          WebKit-based browsers will ignore this. 
    width: -webkit-fill-available;   Mozilla-based browsers will ignore this. 
    width: fill-available; */

}

.ul__grand {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 30px;
  padding-left: 0;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}

.grand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  height: auto;
  min-height: 160px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to right top, #fefae0, #f9f7e2, #f5f4e5, #f1f0e7, #edede9);
  -webkit-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
  -moz-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
  box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
}

.ulTab {
  margin-top: 50px;
}

// Настройка меню табов бутстрапа
.nav-tabs .nav-link {
  background-color: transparent !important;
  color: black !important;
}

.nav-tabs .nav-link.active {
  font-weight: bold;
}

//настройки страницы pkatalog
.isnotmodel {
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .showcasespan {
    margin-top: 10px;
  }
}

.batton__setpart {
  display: flex;
  justify-content: center;
  margin: 50px;
}

.showcase {
  background-color: white;

  @media (max-width: 770px) {
    order: -1;
  }

  .showcase__title {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  img {
    width: 428px;

    @media (max-width: 450px) {
      width: 320px;
    }

  }
}

// настройки пагинации
.active .page-link {
  background-color: #181510 !important;
  border-color: #181510 !important;
}

.page-link {
  color: #181510;
}

//настройки админ панели
.adminPage {
  background-image: linear-gradient(to right top, #dfd045, #90d96e, #2cd8a6, #00cfd6, #00c1ef, #39c0f3, #54c0f6, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  height: 600px;
}

.loginPage {
  display: flex;
  height: 550px;
  justify-content: center;
  align-items: center;
}

//редактор запчастей
.newpart__page {
  background-image: linear-gradient(to right top, #dfd045, #90d96e, #2cd8a6, #00cfd6, #00c1ef, #39c0f3, #54c0f6, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  height: 600px;
}

.new__part {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
}

//страница поиска
.searchSpinner {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.search__link {
  color: #00c1ef;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #DFD045;
  }
}

.search__panel {
  display: flex;
  margin: 20px 0 20px 0;
  justify-content: center;
}

// Настройки экшн тизера запчастей
.action__tizer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(to right, #181510 7%, rgba(0, 0, 0, 0) 93%), url(../assets/img/backgroundPartsImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 400px;
  text-align: center;

  @media (max-width: 720px) {
    height: 300px;
  }
}

.action__title {

  h2 {
    color: #fff;
    font-size: 58px;
  }

  @media (max-width: 870px) {
    h2 {
      font-size: 48px;
    }
  }

  @media (max-width: 720px) {
    h2 {
      font-size: 38px;
    }
  }

  @media (max-width: 570px) {
    h2 {
      font-size: 30px;
    }
  }

  @media (max-width: 450px) {
    h2 {
      font-size: 25px;
    }
  }
}

.action__text {
  margin: 30px 0 40px 0;

  p {
    margin: 0 0;
    color: #fff;
    font-size: 18px;

  }

  @media (max-width: 720px) {
    margin: 10px 0 0px 0;

    p {
      font-size: 14px;
    }
  }
}

.text__margin {
  margin-top: 30px !important;

  @media (max-width: 450px) {
    margin: 0 0 !important;
  }
}

.action__body {
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 840px) {
    flex-direction: column;
    align-items: center;
  }
}

//Страница о компании
.company__teazer {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 55% 25% 20%;
  margin: 20px 0 50px 0;
  /* -webkit-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
  -moz-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
  box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1); */

  @media (max-width: 1000px) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(6, auto);

    .company__teazer_text_reverse {
      grid-row: 3;
      margin-bottom: 20px;
    }

    .company__teazer__text {
      margin-top: 20px;
    }
  }

}

.company__teazer__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;

  p {
    margin: 10px 0 10px 0;
  }
}

.company__teazer__img {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.company__recvizit {
  display: grid;
}

//Страница сервис
.service__teazer {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 33%;
  margin: 20px 0 50px 0;

  @media (max-width: 1000px) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(6, auto);

    .service__teazer_text_reverse {
      grid-row: 3;
      margin-bottom: 20px;
    }

    .service__teazer__text {
      margin-top: 20px;
    }

    .service__row {
      margin: 20px 0 20px 0;
    }
  }

}

.service__teazer__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;

  p {
    margin: 10px 0 10px 0;
  }
}

.service__teazer__img {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.service__contact {
  position: relative;

  img {

    width: 25px;
    height: 25px;
    position: absolute;

  }

  h4 {
    padding-left: 40px;
  }

  a {
    display: block;
    text-decoration: none;
    padding-left: 40px;
    font-weight: 300;
    font-size: calc(1rem + .3vw);
    line-height: 1.2;

    &:hover {
      color: #DFD045;
    }
  }

  p {
    margin: 0 10px 0 10px;
    font-size: calc(1.275rem + .3vw);
  }
}

//Настройки страницы навесного оборудования
.naves__title {
  display: flex;
  /* padding-left: 2rem; */
  /* font-weight: 800; */
  margin: 15px 0 15px 0;
  flex-direction: column;
  align-items: flex-start;
}

//Настройки страницы информации о навесном оборудовании

.benefit {
  background-color: #fff;
  padding-top: 10px;
}

.textline {
  border-bottom: 1px solid #86857d;
  padding-bottom: 30px;
}

.sectionimage {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* img {
    width: 400px;
  } */

  @media (max-width: 650px) {
    img {
      width: 200px;
    }
  }
}

.naves_info_tizer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 50px 0 50px 0;

  img {
    width: 250px;
  }

  div {
    width: 400px;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    img {
      width: 300px;
    }

    div {
      margin-top: 20px;
      width: auto;
    }
  }
}

.benefit_li {
  display: flex;
  align-items: center;
  padding: 0 0 !important;

  img {
    width: 40px;
    margin-right: 10px;
  }
}

.benefit_title {
  // border-bottom: 1px solid #86857d;
  //padding-bottom: 10px;

  p {
    color: #86857d;
    margin-bottom: 0;
  }
}

.table_image {
  max-width: 100%;
  height: auto;
}