@import '../variables';

.card-block {
  //width: 280px;
  text-align: center;
  margin-bottom: 65px;
  overflow: hidden;

  &__image {
    width: 100%;
    height: 270px;

  }

  &__title {
    display: grid;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 1%;
    margin-bottom: 20px;
    padding: 20px 0px;
    border-top: 3px solid $orange;
    justify-content: center;
  }

  p {
    padding: 0px 5px;
  }
}