.root {
    display: block;
    background-color: #181510;
    padding: 5px 0;
    height: auto;

    h2,
    h3,
    h4,
    p {
        color: #dfd045;
    }

    h4 {
        margin-bottom: 10px;
        margin-left: 10px;
    }

}

.footer_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;

    @media (max-width: 750px) {
        flex-direction: column;

        .footer_contact {
            margin-bottom: 5px;
        }
    }

    iframe {
        @media (max-width: 400px) {
            width: 360px;
            margin-top: 10px;
        }
    }
}

.footer_contact {
    margin-bottom: 5px;
    position: relative;

    img {
        color: aliceblue;
        width: 25px;
        height: 25px;
        position: absolute;

    }

    h4 {
        padding-left: 40px;
    }

    a {
        display: block;
        text-decoration: none !important;
        color: #fff;
        width: fit-content;
        padding-left: 40px;
        font-weight: 300;
        font-size: calc(1rem + .3vw);
        line-height: 1.2;

        &:hover {
            color: #DFD045;
        }
    }

    p {
        margin: 0 10px 0 10px;
        font-size: calc(1.275rem + .3vw);
    }
}

.footer_basweblink {
    display: flex;
    height: 30px;
    justify-content: center;
}