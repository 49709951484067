.root {
    width: 300px !important;

    img {
        width: 60px !important;
        // height: fit-content !important;
        padding-right: 5px;
    }

    a {
        font-size: 12px;
        font-weight: bold;
        padding-left: 5px !important;
        // border-bottom: 1px solid blue;
    }
}