.teh-card {
    width: 350px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: white;
    -webkit-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
    -moz-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);
    box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.1);

    &:hover {
        -webkit-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.2);
        -moz-box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.2);
        box-shadow: 0px 2px 14px 3px rgba(9, 10, 11, 0.2);
        cursor: pointer;
    }

    &__image {
        width: 100%;
        height: 250px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &__title {
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 1%;
        margin: 20px 0px;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 5px;
    }

    &__row {
        display: flex;
        justify-content: space-between;

        span {
            display: inline-block;
            font-weight: 600;
        }
    }
}