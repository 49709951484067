.root {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 3fr;
    //align-items: center;
    height: auto;
    margin-bottom: 100px;

    .model-item__title {
        cursor: pointer;
    }

    @media (max-width: 770px) {
        grid-template-rows: 1fr;
        grid-template-columns: none;
        justify-items: center;
    }
}