@import '../variables';

.header {
  * {
    padding: 0;
    margin: 0;
  }

  //border-bottom: 1px solid $gray-line;
  padding: 5px 0;
  background-color: #181510;

  .header__container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 2fr 0.1fr;
    grid-template-rows: 0.5fr;
    gap: 2px 0px;
    grid-template-areas: "A B C";
    justify-items: center;
    align-items: center;


    @media (max-width: 1176px) {
      grid-template-areas: "A A A A  A A A A  C C C C"
        "B B B B  B B B B  B B B B";
    }

    @media (max-width: 732px) {
      grid-template-areas: "B A C";
      grid-template-columns: 40px auto 0.1fr;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    grid-area: A;
    cursor: pointer;


    img {
      margin-right: 5px;
      width: 100px;
    }

    h1 {
      color: #fff;
      font-size: 25px;
      letter-spacing: 1%;
      //text-transform: uppercase;
      font-weight: 800;
    }

    p {
      color: #DFD045;
    }

    @media (max-width:732px) {
      img {
        width: 50px;
      }
    }
  }

  h4 {
    padding-left: 40px;

  }
}