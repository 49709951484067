.model-item {
    //display: grid;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    @media (max-width: 1095px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 825px) {
        grid-template-columns: 1fr;
    }
}


.model-item__title {
    color: aliceblue;
    display: grid;
    text-align: center; //justify-content: center;
    align-content: center;
    font-size: 16px;
    width: 240px;
    height: auto;
    min-height: 40px;
    background-color: #282828;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-left: 5px;
    cursor: pointer;

    &:hover {
        border: 2px solid #ffff00;
    }
}