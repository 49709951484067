.root {
    position: relative;

    @media (max-width: 450px) {
        display: flex;
    }
}

.input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    padding-left: 30px !important;
    width: 350px;
    border-radius: 8px 0 0 8px;
    font-size: 16px;

    @media (max-width: 464px) {
        width: 300px;
    }

    &:focus {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
}



.icon {
    width: 22px;
    height: 22px;
    opacity: 0.4;
    position: absolute;
    left: 5px;
    top: 10px;
}

.clearIcon {
    width: 18px;
    height: 18px;
    opacity: 0.4;
    position: absolute;
    left: 326px;
    top: 13px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 464px) {
        left: 275px;
    }
}